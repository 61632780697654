import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { useStaticQuery, graphql } from "gatsby"
import WorkCard from "./WorkCard"
import HomeStyles from "../styles/components/HomeWorkSection.module.scss"
import Styles from "../styles/components/Expertise.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PlatformImages from "../components/expertisePlatform"

const ExpertiseWebsite = () => {
  const data = useStaticQuery(graphql`
    query websiteCaseStudySection {
      allCaseStudiesJson(
        limit: 2
        filter: { frontmatter: { workType: { eq: "technology" } } }
      ) {
        edges {
          node {
            id
            challenge
            outcome
            frontmatter {
              slug
              workTitle
              featureImage
              companyName
              workType
            }
          }
        }
      }
    }
  `)
  const latestPosts = data.allCaseStudiesJson.edges.filter(
    caseStudy => caseStudy.node.frontmatter.workType == "tech"
  )
  return (
    <div>
      <div
        className={`${Styles["background"]} ${
          Styles["section-container"]
        } container-padding`}
      >
        <div className={Styles["section-line--content-container"]}>
          <div className={Styles["section-line--title"]}>
            <h5>Approach</h5>
          </div>
          <div className={Styles["section-divider"]}>
            <div className={Styles["section-divider--text"]}>
              <h1 className={Styles["section-title--big"]}>Tech Integration</h1>
              <ScrollAnimation animateIn="fadeIn">
                <div className={Styles["section-text--divider"]}>
                  <p>
                    We're contantly thinking of how growing businesses can
                    leverage the power of emerging technologies to drive more
                    innovation. New technologies could provide new ways of
                    tackling old problems.{" "}
                    <span>
                      Our approach with this is to education C-Suite and other
                      stakeholders on the impact these technologies can have and
                      how to implement them into current operations.
                    </span>
                    <br />
                    <br />
                    We do this by isolating small problems and running
                    experiments using data analytics and machine learning to
                    help set the pathway for how similar and/or bigger problems
                    can be tackled using such technologies in the future.
                  </p>
                </div>
              </ScrollAnimation>
              <br />
              <div className={Styles["services-subsection-container"]}>
                <h4>Key Deliverables</h4>
                <div className={Styles["services-subsection"]}>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Strategy</h3>
                    <p>Business Consultation</p>
                    <p>C-Suite Training</p>
                    <p>Innovation Strategy</p>
                    <p>Experimentation</p>
                    <p>Partnerships</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Design</h3>
                    <p>Smart Office Design</p>
                    <p>Environment Design</p>
                    <p>Communication Design</p>
                    <p>Security Design </p>
                    <p>Cloud Infrastructure Design</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Implementation</h3>
                    <p>Machine Learning</p>
                    <p>Power BI Dashbaords</p>
                    <p>Cloud Implemention</p>
                    <p>Process Automation</p>
                    <p>Secure Communications</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div className={Styles["process-section"]}>
          <div className={`${Styles["section-line--title"]} container-padding`}>
            <h5 className={Styles["section-line--dark"]}>Process</h5>
          </div>
          <div
            className={`${
              Styles["process-section-container"]
            } container-padding`}
          >
            <h3>Tech Integration Process</h3>
            <div className={Styles["wrap-collabsible"]}>
              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Needs Assessment
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We approach the stakeholders to understand their concerns,
                    challenges and problems relating to emerging technologies
                    and also educate stakeholders on them.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Technology Planning and Roadmap
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We plan and design a roadmap depending on the needs and
                    activities of the business designing experiments that the
                    business can begin to perform to test new ideas.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Experimentation and Data Collection
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We focus on implementing the roadmap through
                    experimentation, we understand how unwise it would be to
                    directly implement new technology directly on business
                    activities without having to test them first.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Feedback and Integration
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We take the feedback and results from the experiments and
                    integrate the technologies into the business process where
                    necessary.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Maintenance and Improvements
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Following the integration we work with the team to maintain
                    and improve the technology, performing more experiments and
                    training more people on these areas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-padding">
          {/* the platform logos */}
          <PlatformImages tech />
        </div>
        <div className="container-padding">
          {latestPosts.length !== 0 && (
            <div>
              <div className={Styles["section-line--title--dark"]}>
                <h5>Work</h5>
              </div>
              <div>
                <div className={Styles["case-study-area"]}>
                  <h2 className={Styles["featured-head-text"]}>
                    Featured Case Studies
                  </h2>
                  <div className={HomeStyles["section-portfolio"]}>
                    {latestPosts.map(({ node: post }) => (
                      <WorkCard
                        key={post.id}
                        companyName={post.frontmatter.companyName}
                        workTitle={post.frontmatter.workTitle}
                        imageSrc={post.frontmatter.featureImage}
                        slug={post.frontmatter.slug}
                      ></WorkCard>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ScrollAnimation animateIn="bounceIn">
        <AniLink
          cover
          to="/expertise/websites"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
          className={Styles["expertise-next-section-link"]}
        >
          <div className={Styles["expertise-next-section"]}>
            <p>Next </p>
            <h4>Websites Development</h4>
          </div>
        </AniLink>
      </ScrollAnimation>
    </div>
  )
}

export default ExpertiseWebsite
