import React from "react"
import Footer from "../../components/footer"
import TechnologyContent from "../../components/expertise-technology"
import PageHero from "../../components/pagehero"
import MobileHeader from "../../components/MobileHeader"

const ServicesTechnology = () => {
  return (
    <div>
      <MobileHeader>
      </MobileHeader>
      <PageHero
        heading="Technology Transformation"
        description="Living in the future..Today"
        imgSrc="../../tech-bg.jpg"
        pageTitle="Tech"
      ></PageHero>
      <TechnologyContent />
      <Footer></Footer>
    </div>
  )
}

export default ServicesTechnology
